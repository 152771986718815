<template>
  <div>
    <b-card
      class="transantion-download"
    >
      <div class="m-2 up-content">
        <b-card-title>Request Log</b-card-title>
        <b-card-sub-title>
          Only latest 20 records will be shown
        </b-card-sub-title>
        <b-button
          class="refresh-btn"
          variant="primary"
          @click="refresh"
        >Refresh</b-button>
      </div>
      <b-overlay
        variant="white"
        :show="showLoading"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
      >
        <table-list
          :table-data.sync="tableData"
          :list.sync="tableList"
          :page-num.sync="currentPage"
          :total-list.sync="totalList"
        />
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import {
  defineComponent, ref, getCurrentInstance, watch, onMounted,
} from '@vue/composition-api'
import {
  BCard, BCardTitle, BCardSubTitle, BButton, BOverlay,
} from 'bootstrap-vue'
import { showToast } from '@/libs/utils'
import storage from '@/libs/storage'

export default defineComponent({
  components: {
    BCard,
    BCardTitle,
    BCardSubTitle,
    BButton,
    BOverlay,
  },
  setup() {
    const { proxy } = getCurrentInstance()
    const { $api } = proxy
    const showLoading = ref(false)
    // 列表
    const download = row => {
      const userData = storage.getItem('userData')
      const { userInfo } = userData
      window.open(
        `${process.env.VUE_APP_BASE_OPEN_API}/api/v1/open/download?id=${row.id}&transaction_id=${row.transaction_id}&uuid=${userInfo.user_id}`,
      )
    }
    const tableCallback = (type, data) => {
      if (type === 'download') { download(data) }
    }
    const currentPage = ref(1)
    const tableList = ref([])
    const totalList = ref(0)
    const tableData = ref({
      tableColumns: [
        { key: 'transaction_id', label: 'Transaction ID' },
        { key: 'file_gen_status_text', label: 'status' },
        { key: 'created_at', label: 'create time' },
        { key: 'updated_at', label: 'update time' },
        { key: 'actions', label: 'actions' },
      ],
      templates: [
        { key: 'created_at', tType: 'datetime' },
        { key: 'updated_at', tType: 'datetime' },
        { key: 'actions', tType: 'transaction_download_actions', callback: tableCallback },
      ],
    })

    const refresh = async type => {
      showLoading.value = true
      const res = await $api.transactionDownloadList()
      showLoading.value = false
      const { code, data, message } = res.data
      if (code === 200) {
        if (type !== 'refresh') { currentPage.value = 1 }
        tableList.value = data && data.list
        totalList.value = data && data.list.length
      } else {
        showToast({ proxy: this, title: 'danger', message })
      }
    }
    watch([currentPage], async () => { await refresh('refresh') })
    onMounted(() => refresh())
    return {
      showLoading,
      tableData,
      tableList,
      currentPage,
      totalList,
      refresh,
      BOverlay,
    }
  },
})
</script>

<style lang="scss">
  .transantion-download {
    .up-content {
      position: relative;
      .refresh-btn {
        position: absolute;
        top: 10px;
        right: 30px;
      }
    }

  }
</style>
